:root {
  --font-size: 17px;
  --line-height: 1.7;
  --light-green: #2c5700;
  --dark-green: #153d20;
  color-scheme: dark;
}

* {
  box-sizing: border-box;
}

html {
  min-width: 320px;
  font: var(--font-size) / var(--line-height) Roboto, sans-serif;
  background: linear-gradient(to bottom, var(--light-green), var(--dark-green)) var(--light-green);
  color: #fff;
  word-break: break-word;
  overflow-y: auto;
}

body {
  min-height: 100vh;
  background: radial-gradient(400px 100px at 50% 100px, #ffffff21, #ffffff0a, #fff0);
  border: .5px solid;
  border-bottom: none;
  flex-direction: column;
  margin: 0;
  display: flex;
}

a {
  color: inherit;
}

main {
  flex-direction: column;
  flex-grow: 1;
  margin: 0 20px;
  padding-bottom: 40px;
  display: flex;
  position: relative;
}

main > * {
  margin: 0 0 calc(var(--font-size) * var(--line-height));
}

main > :not(h2), footer > :not(input) {
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  text-align: center;
  margin: 7vh auto .5em;
  font-size: 2.5em;
  line-height: 1.1;
  max-width: none !important;
}

h1 img {
  width: 320px;
  margin: auto;
  display: block;
}

h1 a {
  text-decoration: none;
}

h1, h2, h3 {
  font-family: roboto slab, serif;
  font-weight: 300;
}

h2 {
  flex-direction: row;
  font-size: 30px;
  display: flex;
}

h2:before, h2:after {
  content: "";
  border-bottom: .5px solid;
  flex-grow: 1;
  margin-bottom: 1.2ex;
}

h2:before {
  margin-right: 20px;
}

h2:after {
  margin-left: 20px;
}

textarea {
  tab-size: 2;
  border: 3px solid silver;
  border-radius: 4px;
  padding: .2em;
  font-size: 10px;
  line-height: 1.1;
}

input[type="text"] {
  border: .5px solid;
  border-radius: 4px;
}

input::placeholder {
  color: #ffffff80;
}

input[type="url"], input[type="text"], textarea {
  font: inherit;
  color: inherit;
  width: 100%;
  background: #153d2088;
  outline: none;
  margin: 0;
  padding: 0 .3em;
}

textarea:focus, input[type="text"]:focus {
  background: var(--dark-green);
}

ol {
  padding-left: 0;
  list-style: none;
}

ul {
  padding-left: 3.5em;
  list-style: none;
}

ul li:before {
  content: "→ ";
  margin-left: -1.5em;
  position: absolute;
}

.text-monospace {
  font-family: monospace !important;
}

.text-pre {
  white-space: pre !important;
}

.d-inline-block {
  display: inline-block !important;
}

.text-align-center {
  text-align: center;
}

.status strong:before {
  content: url("logo.f1d15ad6.svg") "\a ";
  white-space: pre;
  filter: invert();
}

.status:empty {
  display: none;
}

.status strong:before {
  content: url("logo.f1d15ad6.svg") "\a ";
  white-space: pre;
  filter: invert();
}

input[type="url"], #token {
  font: inherit;
  text-align: center;
  max-width: 100%;
  background-color: #ffffff1a;
  border: solid;
  border-radius: .5em;
  margin: .5em 0;
  padding: .5em;
}

a, label {
  cursor: pointer;
  text-decoration: underline;
}

[type="checkbox"] {
  visibility: hidden;
  position: absolute;
}

[type="checkbox"]:checked + * + *, [type="checkbox"]:not(:checked) + * {
  display: none;
}

#token:valid {
  color: var(--github-green);
  border: .1em solid;
}

#token:invalid {
  color: var(--github-red);
  border: .1em solid;
}

[name="url"] {
  font-size: .6em;
}

.info {
  margin-top: .5em;
}

pre {
  text-align: left;
  max-height: 100vh;
  padding: 0 10px;
  font-size: 10px;
  overflow: auto;
}

pre:first-line {
  font-size: 20px;
}

/*# sourceMappingURL=index.95bc90df.css.map */
